import clsx from 'clsx'
import {MDXRemote, MDXRemoteSerializeResult} from 'next-mdx-remote'
import {ForwardedRef, forwardRef, ReactNode} from 'react'
import Container, {ContainerProps} from '../../atoms/container'
import GridContainer from '../../atoms/grid-container'
import GridItem from '../../atoms/grid-item'
import LargeParagraph from '../../molecules/large-paragraph'

export interface PageCoverProps
  extends Omit<ContainerProps, 'media' | 'title' | 'ref'> {
  /**
   * Text to be displayed as title.
   */
  title: ReactNode
  /**
   * Text to be displayed below the title.
   */
  description: MDXRemoteSerializeResult
  /**
   * Buttons to be displayed below the description. Useful if Call to Action
   * button needs to be displayed.
   */
  buttons?: ReactNode
  /**
   * Media to be displayed on the right-hand side of the cover section.
   */
  media: ReactNode
}

export const PageCover = forwardRef(function PageCover(
  {
    title,
    description,
    buttons,
    media,
    children,
    className,
    ...props
  }: PageCoverProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Container
      component="section"
      className={clsx(
        'grid gap-20 place-content-center pb-12 sm:pb-20 lg:pb-0',
        className,
      )}
      ref={ref}
      {...props}
    >
      <GridContainer
        disableDefaultColumns
        className="items-center grid-flow-row lg:grid-flow-col grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-4"
      >
        <GridItem className="grid gap-4 order-2 lg:order-1 place-items-center lg:place-items-start motion-safe:animate-fade-in-quick">
          {title}

          <MDXRemote
            {...description}
            components={{
              p: LargeParagraph,
            }}
          />

          {buttons && (
            <div className="grid grid-flow-col place-items-start place-content-center lg:place-content-start gap-4 mt-4">
              {buttons}
            </div>
          )}
        </GridItem>

        <GridItem className="order-1 lg:order-2 lg:mb-0">{media}</GridItem>
      </GridContainer>

      {children}
    </Container>
  )
})

export default PageCover
