import clsx from 'clsx'
import Image from 'next/image'
import {HTMLProps, ReactNode, useEffect, useState} from 'react'
import {useInView} from 'react-intersection-observer/useInView'
import {DataWithIcon} from '../../../types'
import Heading from '../../atoms/heading'
import Paragraph from '../../atoms/paragraph'

export interface InfoBoxProps
  extends Omit<HTMLProps<HTMLDivElement>, 'ref'>,
    Partial<DataWithIcon> {
  /**
   * Title of information box rendered as a Heading component.
   */
  title: string
  /**
   * Determines whether maximum width of Heading component should be disabled.
   *
   * @default false
   */
  disableHeadingMaxWidth?: boolean
  /**
   * Additional child components displayed after the body paragraph.
   *
   * @default null
   */
  additionalChildren?: ReactNode
  /**
   * Determines whether or not the colored underline should be displayed.
   */
  hideUnderline?: boolean
  iconOnTop?: boolean
}

const colorPalette = [
  'after:bg-pastel-pink-salmon',
  'after:bg-pastel-sundown',
  'after:bg-pastel-negroni',
  'after:bg-pastel-chrome-white',
  'after:bg-pastel-cruise',
  'after:bg-pastel-periwinkle-gray',
  'after:bg-pastel-your-pink',
  'after:bg-pastel-blue-chalk',
  'after:bg-pastel-snowy-mint',
  'after:bg-pastel-watusi',
  'after:bg-pastel-pastel-pink',
  'after:bg-pastel-lavender-purple',
]

const widthList = ['after:w-12', 'after:w-14', 'after:w-16', 'after:w-20']

export function InfoBox({
  title,
  icon,
  iconAlt,
  iconOnTop,
  disableHeadingMaxWidth,
  additionalChildren,
  children,
  className,
  hideUnderline,
  ...props
}: InfoBoxProps) {
  const [underlineColor, setUnderlineColor] = useState<string>()
  const [decorationWidth, setDecorationWidth] = useState<string>()
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.35,
    skip: true,
    initialInView: true,
  })

  useEffect(() => {
    if (!hideUnderline) {
      const underlineIndex = Math.floor(Math.random() * colorPalette.length)
      const widthIndex = Math.floor(Math.random() * widthList.length)

      setUnderlineColor(colorPalette[underlineIndex])
      setDecorationWidth(widthList[widthIndex])
    }
  }, [hideUnderline])

  return (
    <div
      className={clsx(
        'grid grid-flow-row gap-4 place-content-start',
        iconOnTop ? 'md:grid-flow-row' : 'md:grid-flow-col',
        inView
          ? 'motion-safe:animate-fade-in-slide-up md:animate-none'
          : 'opacity-0',
        className,
      )}
      ref={ref}
      {...props}
    >
      {icon && (
        <div className="w-14 h-14">
          <Image
            src={icon}
            alt={iconAlt}
            layout="responsive"
            sizes="128px"
            width={64}
            height={64}
            quality={100}
          />
        </div>
      )}

      <div className="grid gap-2">
        <Heading
          component="strong"
          variant="h3"
          disableMaxWidth={disableHeadingMaxWidth}
          className={clsx(
            !hideUnderline &&
              clsx(
                decorationWidth,
                underlineColor,
                decorationWidth &&
                  underlineColor &&
                  'after:block after:h-1 after:rounded-sm after:mt-0.5',
              ),
          )}
        >
          {title}
        </Heading>

        <Paragraph>{children}</Paragraph>

        {additionalChildren}
      </div>
    </div>
  )
}

export default InfoBox
