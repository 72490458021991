import {HTMLProps} from 'react'
import Container, {ContainerProps} from '../container'
import clsx from 'clsx'

export interface HighlightedSectionProps extends HTMLProps<HTMLDivElement> {
  /**
   * Props applied to the inner container element.
   */
  containerProps?: Partial<Omit<ContainerProps, 'ref'>>
}

export function HighlightedSection({
  className,
  containerProps,
  children,
  ...props
}: HighlightedSectionProps) {
  return (
    <section
      className={clsx(
        'relative py-12 md:py-20 z-0 after:bg-gray-100 after:absolute after:top-0 after:bottom-0 after:right-0 after:left-0 after:skew-y-0 md:after:-skew-y-2 lg:after:-skew-y-3 after:-z-1',
        className,
      )}
      {...props}
    >
      <Container
        className="grid gap-8 justify-items-center z-10"
        {...containerProps}
      >
        {children}
      </Container>
    </section>
  )
}

export default HighlightedSection
