import clsx from 'clsx'
import Button, {ButtonProps} from '../../atoms/button'
import ArrowRightCircleIcon from '../../atoms/icons/arrow-right-circle-icon'

export function LinkButton({children, className, ...props}: ButtonProps) {
  return (
    <Button
      className={clsx(
        'group text-center grid grid-flow-col items-center gap-2 place-content-start md:w-full max-w-xs justify-center justify-self-center',
        className,
      )}
      renderAsLink
      {...props}
    >
      <span>{children}</span>

      <ArrowRightCircleIcon className="scale-100 md:scale-90 md:group-hover:scale-100 origin-left transition-transform" />
    </Button>
  )
}

export default LinkButton
