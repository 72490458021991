import clsx from 'clsx'
import {HTMLProps} from 'react'

export function ArrowRightCircleIcon({className}: HTMLProps<HTMLOrSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('w-5 h-5', className)}
    >
      <desc>An arrow in a circle pointing right</desc>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7929 6.79289C13.1834 6.40237 13.8166 6.40237 14.2071 6.79289L18.7071 11.2929C19.0976 11.6834 19.0976 12.3166 18.7071 12.7071L14.2071 17.2071C13.8166 17.5976 13.1834 17.5976 12.7929 17.2071C12.4024 16.8166 12.4024 16.1834 12.7929 15.7929L16.5858 12L12.7929 8.20711C12.4024 7.81658 12.4024 7.18342 12.7929 6.79289Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowRightCircleIcon
