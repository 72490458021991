import clsx from 'clsx'
import {HTMLProps} from 'react'

export type NumberBadgeProps = HTMLProps<HTMLSpanElement> & {
  /**
   * @default: 'from-inverse-light to-inverse'
   *
   * Start and stop color for the number badge background gradient. Use TailwindCSS color classes.
   */
  gradientColors?: string
}

export function NumberBadge({
  className,
  children,
  gradientColors = 'from-inverse-light to-inverse',
  ...props
}: NumberBadgeProps) {
  return (
    <span
      className={clsx(
        `flex items-center justify-center rounded-full w-11 h-11 overflow-ellipsis whitespace-nowrap overflow-hidden text-white font-semibold text-lg bg-gradient-to-r`,
        gradientColors,
        className,
      )}
      {...props}
    >
      {children}
    </span>
  )
}

export default NumberBadge
