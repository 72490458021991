import Paragraph, {ParagraphProps} from '../../atoms/paragraph'

export function LargeParagraph({children}: ParagraphProps) {
  return (
    <Paragraph fontSize="text-xl text-center lg:text-left">
      {children}
    </Paragraph>
  )
}

export default LargeParagraph
